<template>
    <div class="net-content">
        <div class="top-select">
            <span>状态：</span>
            <el-select v-model="status" placeholder="请选择" @change="getList">
                <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <el-table :data="studentSubmitList" class="customTable" style="width: 100%; flex: 1; margin-top: 20px"
                  :tree-props="{children: 'subList', hasChildren: 'hasChildren'}"
                  :row-key="getRowKey" :expand-row-keys="expands"
                  default-expand-all
                  height="1%">
<!--            <el-table-column prop="username" label="学生账号" width="200"></el-table-column>
            <el-table-column prop="exam_name" label="考试名称" width="300">
                <template slot-scope="scope">
                    <span v-if="status == 3">{{scope.row.exam_name}}</span>
                    <span v-else>{{scope.row.exam_name}}</span>
                </template>
            </el-table-column>-->
            <el-table-column prop="module" label="考试模块" width="150" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.exam_module}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" width="150" align="center">
                <template slot-scope="scope">
                    {{scope.row.score ? '已批改' : scope.row.status}}
                </template>
            </el-table-column>
            <el-table-column prop="exam_time" label="考试时间" align="center" width="300">
                <template slot-scope="scope">
                    <span v-if="status == 3">{{scope.row.exam_time}}</span>
                    <span v-else>{{scope.row.exam_time}}</span>
                </template>
            </el-table-column>
            <el-table-column align="left" :label="`评分（软文推广最高${articleScore}分，短视频推广最高${videoScore}分）`">
                <template slot-scope="scope">
                    <span v-if="status == 3">该学生暂未提交无法评分</span>
                    <div class="link-input-box" v-else>
                        <el-input-number v-model="scope.row.score" :precision="1" :max="20" :controls="false"></el-input-number>
                        <el-link type="warning" :underline="false" @click="submitScore(scope.row)" class="view-detail">
                            {{status == 1?'确定':'重新批改'}}
                        </el-link>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="link-box">
                        <el-link type="primary" :underline="false" @click="viewArticle(scope.row)"
                                 class="view-detail" v-if="status != 3 && scope.row.exam_module == '软文推广'">查看详情
                        </el-link>
                        <el-link type="primary" :underline="false" @click="downloadFile(scope.row)"
                                 class="view-detail" v-if="status != 3 && scope.row.exam_module == '短视频推广'">下载文件
                        </el-link>
                        <el-link type="primary" :underline="false" @click="viewGrading(scope.row)" class="view-detail">
                            评分标准
                        </el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                       :current-page="studentSubmitPages.currentPageNum"
                       :page-size="studentSubmitPages.eachPageNum"
                       :total="studentSubmitPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="studentSubmitCurrentChange">
        </el-pagination>
        <el-dialog title="评分标准"
                   :visible.sync="gradingDialog" width="830px" @close="handleCloseGradingDialog"
                   custom-class="green-dialog goods-score-dialog">
            <div class="upload-file-content">
                <el-table :data="gradingList" class="customTable goods-text-table" style="width: 100%; flex: 1;"
                          height="1%"
                          :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333', background: '#F2F3FA',fontSize: '14px'}"
                          :cell-style="{fontSize: '14px',color: '#333333',height: '40px'}">
                    <el-table-column prop="index" label="序号" align="center"></el-table-column>
                    <el-table-column prop="content" label="考核内容" align="center"></el-table-column>
                    <el-table-column prop="require" label="考核要点" align="center"></el-table-column>
                    <el-table-column prop="standard" label="评分标准" align="center"></el-table-column>
                    <el-table-column prop="score" label="配分" align="center"></el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {teacheropNetworkPromotionList, teacheropTeacherScore} from '@/utils/apis'

    export default {
        name: "Index",
        data() {
            return {
                status: 1,
                exam_id: this.$route.query.examId,
                studentSubmitList: [],
                studentInfo: {},
                //评分标准列表
                gradingList: [],
                //评分标准弹窗
                gradingDialog: false,
                //分页
                studentSubmitPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                options: [
                    {
                        id: 1,
                        label: '待批改'
                    },
                    {
                        id: 2,
                        label: '已批改'
                    },
                    {
                        id: 3,
                        label: '未提交'
                    }
                ],
                expands: [],
                articleScore: 0,
                videoScore: 0
            }
        },
        created() {
            this.getList();
        },
        methods: {
            getList() {
                let param = {
                    exam_id: this.exam_id,
                    status: this.status,
                    page: this.studentSubmitPages.currentPageNum,
                    limit: this.studentSubmitPages.eachPageNum
                }
                teacheropNetworkPromotionList(param).then(res => {
                    this.studentSubmitPages.total = res.data.total;
                    this.studentInfo = res.data;
                    this.articleScore = Number(res.data.article_promote_score);
                    this.videoScore = Number(res.data.video_promote_score);
                    if (this.studentInfo.list) {
                        this.studentSubmitList = res.data.list.map(item => {
                            item.subList = [];
                            item.user_id = item.id;
                            //如果两个都有，优先显示软文
                            if (item.article_promote != null && item.video_promote != null) {
                                let obj = {}
                                obj = item.video_promote
                                obj.status = item.status
                                if(this.status == 3){
                                    obj.begin_time = item.begin_time
                                    obj.id = Date.parse(new Date()) + '_' + Math.ceil(Math.random() * 99999)
                                } else {
                                    obj.exam_time = item.exam_time
                                }
                                obj.score = item.video_promote.score ? item.video_promote.score : '';
                                obj.exam_module = item.video_promote.module
                                item.subList.push(obj)
                                item.exam_module = item.article_promote.module
                                item.score = item.article_promote.score ? item.article_promote.score : '';
                            }
                            if (item.article_promote === null) {
                              item.exam_module = '软文推广';
                            }
                            if (item.video_promote === null) {
                              item.exam_module = '短视频推广';
                            }
                            if (item.article_promote != null && item.video_promote == null) {
                                item.exam_module = item.article_promote.module
                                item.score = item.article_promote.score ? item.article_promote.score : '';
                            }
                            if (item.article_promote == null && item.video_promote != null) {
                                item.exam_module = item.video_promote.module
                                item.score = item.video_promote.score ? item.video_promote.score : '';
                            }

                            if (item.article_promote === null && item.video_promote === null) {
                                item.exam_module = '软文/短视频推广';
                            }
                            return item
                        });
                    }
                    this.gradingList = res.data.scoring_criteria
                }).catch(err => {
                    console.error(err)
                })
            },
            getRowKey(row) {
                return row.id;
            },
            viewArticle(row) {
                let role = localStorage.getItem('role');
                if (role == 3) {
                    this.$router.push({
                        path: '/teacher/articleDetail',
                        query: {
                            article_id: row.article_promote.id
                        }
                    })
                } else {
                    let url = this.$router.resolve({
                      path: '/judges/articleDetail',
                      query: {
                        article_id: row.article_promote.id
                      }
                    });
                    window.open(url.href, '_blank');
                }
            },
            submitScore(row) {
                let type = row.exam_module == '软文推广' ? '1' : '2'
                let param = {
                    exam_id: this.exam_id,
                    stu_id: row.user_id,
                    score: row.score,
                    type: type
                }
                if (Number(param.type) === 1 && Number(param.score) > this.articleScore ) {
                    return this.$message.warning('软文推广最高分值不能超过' + this.articleScore + '分');
                }
                if (Number(param.type) === 2 && Number(param.score) > this.videoScore) {
                    return this.$message.warning('短视频推广最高分值不能超过' + this.videoScore + '分')
                }
                teacheropTeacherScore(param).then(res => {
                    this.$message.success(res.msg);
                    this.getList();
                }).catch(err => {
                    console.error(err)
                })
            },
            //下载文件
            downloadFile(row) {
                let url = row.video_promote ? row.video_promote.live_path : row.live_path
                window.open(url, '_blank')
            },
            //查看评分标准
            viewGrading() {
                this.gradingDialog = true;
            },
            //关闭评分标准弹窗
            handleCloseGradingDialog() {
                this.gradingDialog = false;
            },
            //分页
            studentSubmitCurrentChange(val) {
                this.studentSubmitPages.currentPageNum = val;
                this.getList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .net-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;

        ::v-deep .el-table {
            .el-table__header-wrapper .has-gutter tr {

                .is-leaf {
                    &:nth-child(6) {
                        position: absolute;
                        z-index: 1;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .top-select {
            margin-top: 20px;
        }

        .link-input-box {
            display: flex;

            .el-input-number {
                width: 88px;
            }

            .el-link {
                margin-left: 17px;
            }
        }

        .link-box {
            .el-link + .el-link {
                &:before {
                    display: none;
                }
            }
        }

        .goods-img-dialog {
            .upload-file-content {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 445px;

                .big-img {
                    width: 444px;
                    height: 444px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .small-img {
                    flex: 1;
                    width: 1%;
                    margin-left: 94px;

                    img {
                        width: 80px;
                        height: 80px;
                        margin-bottom: 15px;
                        border: 2px solid transparent;
                        box-sizing: border-box;

                        &:nth-child(2n-1) {
                            margin-right: 18px;
                        }

                        &:hover {
                            cursor: pointer;
                            border: 2px solid #2DC079;
                        }
                    }
                }
            }
        }
    }
</style>